import React from 'react'
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SidebarForm from "../components/SidebarForm"
import ReactMarkdown from 'react-markdown'
import Seo from "../components/seo"


const FenceTemplate = ({ data }) => {

    const { title, code, featuredimage, content } = data.markdownRemark.frontmatter

    return (
        <Layout>
            <div className="bg-white drop-shadow-xl border-t-7 md:px-8 px-4 mt-8 pb-6 max-w-6xl mx-auto">
                <div className="flex md:flex-row flex-col md:text-left text-center">
                    <div className="md:w-8/12 w-full">
                        <h1 className="text-3xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-8">{title && title}</h1>
                        {content && (
                            <ReactMarkdown
                                children={content}
                                components={{
                                    p: ({ children }) => {
                                        return (
                                            <p className='font-light text-sm text-gray-600 mb-6'>
                                                {children}
                                            </p>
                                        )
                                    },
                                    h2: ({ children }) => {
                                        return (
                                            <h2 className='text-center title text-3xl font-light pb-5 md:my-8 mb-6'>
                                                {children}
                                            </h2>
                                        )
                                    },
                                    h3: ({ children }) => {
                                        return <h3 className='text-2xl font-medium my-5'>{children}</h3>
                                    },
                                    h4: ({ children }) => {
                                        return <h4 className='text-lg font-medium my-5'>{children}</h4>
                                    },
                                    ul: ({ children }) => {
                                        return (
                                            <ul className='m-0 mb-8 p-0 flex flex-wrap'>{children}</ul>
                                        )
                                    },
                                    li: ({ children }) => {
                                        return (
                                            <li className='md:w-2/4 w-full mb-0 text-gray-600 font-light text-sm font1 flex gap-2 items-center leading-6 mb-1'>
                                                <div className='w-3 h-3 mt-1'>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='#707070'
                                                        width='12'
                                                        height='12'
                                                        viewBox='0 0 24 24'
                                                    >
                                                        <path d='M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z' />
                                                    </svg>
                                                </div>
                                                {children}
                                            </li>
                                        )
                                    },
                                    a: ({ href, children }) => (
                                        <a
                                            href={href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline hover:text-blue-800"
                                        >
                                            {children}
                                        </a>
                                    )
                                }}
                            />
                        )}
                        <GatsbyImage image={getImage(featuredimage)} />
                        <p className="text-lg mt-4 font-normal">Product Number : <b>{code}</b></p>
                    </div>
                    <div className="md:w-4/12 w-full">
                        <SidebarForm />
                    </div>
                </div>
            </div>
        </Layout>
    )
}


export const Head = (props) => <Seo title={props?.data?.markdownRemark?.frontmatter?.meta?.seoTitle || ''}
    description={props?.data?.markdownRemark?.frontmatter?.meta?.seoDescription || ''} path={props.location.pathname} />



export const pageQuery = graphql`
  query FenceByID($id: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: {status: {ne: "Draft"}}) {
        frontmatter {
            title
            code
            content
            featuredimage {
                childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
                }
            }
            meta {
                seoTitle
                seoDescription
            }
        }
    }    
  }
`;

export default FenceTemplate